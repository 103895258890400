import React, { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import classes from '../Header/Header.module.css';
import Icon from '../Icons';
import { PropsForExitButton } from './ExitButton';

const UseExitButton = (props:PropsForExitButton) => {
    const {previousPath} = props;
    const [isOpenDialog, stIsOpenDialog] = useState<boolean>(false);
    const [mobileMenu, setMobileMenu] = useState<boolean>(false);
    const isLargeTablet = useMediaQuery({query: "(max-width: 1079px)"});

    const closeDialogBox = useCallback(() => {
        stIsOpenDialog(false);
    }, [stIsOpenDialog])

    const openDialogBox = useCallback(() => {
        stIsOpenDialog(true);
    }, [stIsOpenDialog]);

    const onClickMenuHandle = useCallback(() => {
        setMobileMenu((enabled) => !enabled);
    }, [setMobileMenu])

    const renderMenuContent = useCallback((): JSX.Element | null => {
        if (isLargeTablet) {
            return (
                <div className={classes.menuMobile} onClick={onClickMenuHandle}>
                    <Icon name={"hk_menu"}/>
                </div>
            );
        }
        return null;
    }, [isLargeTablet]);

    const handleExit = useCallback(() =>{
        const localPath = JSON.parse(localStorage.getItem('previousPath') as string);
      window.location.href = previousPath || localPath || `${window.location.origin}`;
    },[previousPath])

    return {
        mobileMenu,
        isOpenDialog,
        openDialogBox,
        closeDialogBox,
        renderMenuContent,
        handleExit
    }
}

export default UseExitButton;
