import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseCartType } from '../../types';
import { ToLocalStringConvert } from '../../Utils/toLocalStringConvert';
import { DiamondLabels, weddingButton } from '../../Utils/commonData';
import { compareObjects } from '../../Utils/compareObjects';
import { getNoBands } from '../../Utils/getNoBands';
import { getRingPrice } from '../../Utils/getRingPriceValue';

const useCart = (props: UseCartType) => {
  const {
    ring,
    footer,
    filter,
    diamond,
    table,
    setFooterToggle,
    setRedirectEditPage,
    setSettingTabIconTrue,
    setDiamondTabIconTrue,
    setCartImageSuccess,
    setCartThumbImageSuccess,
    settingFilter,
    stateManger,
    setBackToSelectDiamondPage,
    toggleScrollTop,
    setRingOptions,
    setRingExtraData,
    setRemoveRingOption,
    setBandTabIndex,
    setFilter,
    cart,
    setAfterRingOptions,
    instanceData: { screenManager },
    setPriceLoading,
    setRingPrice,
    setRingPriceError
  } = props;

  const rpid = JSON.parse(localStorage.getItem('rpid') as string);
  const builder_mode = JSON.parse(localStorage.getItem('builder_mode') as string);
  const uuid = JSON.parse(localStorage.getItem('design_uuid') as string);
  const hbuuid = JSON.parse(localStorage.getItem('hbuuid') as string);
  const [isShowDiamondType, setShowDiamondType] = useState(false);
  const [isShowRingType, setShowRingType] = useState(false);
  const [isShowDoubleFirstBandType, setIsShowDoubleFirstBandType] = useState(false);
  const [isShowDoubleSecondBandType, setIsShowDoubleSecondBandType] = useState(false);
  const [image, setImage] = useState<string[]>([]);
  const [thumbImage, setThumbImage] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    certificate_link,
    scs_certificate_link,
    certification,
    source,
    carat,
    shape,
    cut,
    color,
    clarity,
    measurement,
    table_per,
    depth,
    symmetry,
    polish,
    girdle,
    culet,
    fluorecence,
    Center_Diamond_Shape,
    b2c_price: diamond_b2c_price
  } = diamond.details;

  const {
    Ring_Crown: RingCrownName,
    Ring_Side: RingSideName,
    Ring_Style: RingStyleName,
    Diamond_Type: DiamondTypeName,
    Diamond_Shape: DiamondShapeName,
    Color: ColorName,
    Crown_Color: CrownColorName,
    Crown_Metal: CrownMetalName,
    Metal: MetalName,
    Ring_Size: RingSizeName,
    Engraving: EngravingName
  } = ring.selectedRingDetails;

  const {
    Ring_Style,
    Diamond_Type,
    Diamond_Shape,
    Center_Diamond_Size,
    Ring_Crown,
    Ring_Side,
    Crown_Color,
    Color,
    Crown_Metal,
    Metal,
    Wedding_Band,
    Single_First_Metal_Band,
    Double_First_Metal_Band,
    Double_Second_Metal_Band,
    First_Band_Engraving,
    Second_Band_Engraving
  } = ring.options;

  const [isDisabledBtn,setIsDisabledBtn] = useState<boolean>(false);

  const { ring_tcw, bands_tcw, ring_price, left_band_price, right_band_price } = ring.ringPrice;

  const { Label, image_cache_param } = ring.extraData;

  const customisationAllowed = useMemo(() => {
    return ring.extraData.customisation_allowed;
  }, [ring.extraData.customisation_allowed]);

  const headColor = useMemo(() => {
    return customisationAllowed ? Crown_Color : Color;
  }, [customisationAllowed, Crown_Color, Color]);

  useEffect(() => {
    const cartView = document.querySelector('#cartView') as HTMLElement;
    if (cartView) {
      cartView.addEventListener('keydown', function(event) {
        const isTabPressed = event.key === 'Tab' || event.keyCode === 9;
        if (isTabPressed) {
          const focusableElements = cartView.querySelectorAll<HTMLElement>('a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
          const firstFocusable = focusableElements[0];
          const lastFocusable = focusableElements[focusableElements.length - 1];
          if (event.shiftKey && document.activeElement === firstFocusable) {
            event.preventDefault();
            firstFocusable.focus();
          } else if (!event.shiftKey && document.activeElement === lastFocusable) {
            event.preventDefault();
            lastFocusable.focus();
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const getNoBandsData = async () => {
      if (Ring_Style && Ring_Crown && Diamond_Shape && Ring_Side && !!ring.extraData.Label) {
        const extraData = await getNoBands(ring.extraData, setRingExtraData, ring.options.Ring_Style, ring.options.Ring_Crown, ring.options.Ring_Side, ring.options.Diamond_Shape);
        if (extraData?.Bands === 0) {
          setBandTabIndex(0);
          setRingOptions('Wedding_Band', 'No Band');
          ['Single_First_Band', 'Single_First_Metal_Band', 'Double_First_Band', 'Double_First_Metal_Band', 'Double_Second_Band', 'Double_Second_Metal_Band'].map((value) => setRemoveRingOption(value));
        } else if (extraData?.Bands === 1) {
          if (Wedding_Band === 'Double') {
            setBandTabIndex(1);
            setRingOptions('Wedding_Band', 'Single');
            ['Double_First_Band', 'Double_First_Metal_Band', 'Double_Second_Band', 'Double_Second_Metal_Band'].map((value) => setRemoveRingOption(value));
          }
        }
      }
    };
    getNoBandsData();
  }, [Ring_Style, Ring_Crown, Diamond_Shape, Ring_Side, Center_Diamond_Shape, ring.extraData.Label]);

  useEffect(() => {
    if (Object.keys(ring.options).length > 0 && footer.isRedirectToEdit) {
      const ringPriceData = `${footer.styleID}-${Ring_Style}${Ring_Crown}-RND-${Center_Diamond_Size}-${Ring_Side}-${(Crown_Metal ?? Metal)}-${Metal}-WG-${Diamond_Type}`;
      const priceData = {
        sku: `'${ringPriceData}'`, order_items: Wedding_Band === 'No Band' ? 1 : Wedding_Band === 'Single' ? 2 : 3
      };
      getRingPrice(setPriceLoading, setRingPrice, setRingPriceError, priceData);
    }
  }, [Center_Diamond_Size, Diamond_Type]);

  useEffect(() => {
    const fetchData = async () => {
      if (footer.styleID && Ring_Crown && Diamond_Shape && Center_Diamond_Size && Ring_Side) {
        setImage([]);
        setThumbImage([]);

        const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
        const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${headColor}${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `${Double_Second_Metal_Band?.split('_')[1]}` : ''}${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}`;

        ['V1', 'V2', 'V3', 'V4'].forEach((view: string) => {
          const imagePath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/${view}/2000/fafafa/hq/${uniqueName}${view}2000.jpg?${image_cache_param}`;
          const thumbPath = `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/${view}/150/fafafa/hq/${uniqueName}${view}150.jpg?${image_cache_param}`;
          setImage((prev: string[]) => {
            return [...prev, imagePath];
          });
          setThumbImage((prev: string[]) => {
            return [...prev, thumbPath];
          });
        });
      }
    };
    fetchData();
  }, [headColor, Ring_Style, Ring_Crown, Diamond_Shape, Center_Diamond_Size, Ring_Side, Metal, Color, Diamond_Type, Wedding_Band, Single_First_Metal_Band, Double_Second_Metal_Band, Double_First_Metal_Band]);

  useEffect(() => {
    setCartImageSuccess([...image]);
  }, [image]);

  useEffect(() => {
    setCartThumbImageSuccess([...thumbImage]);
  }, [thumbImage]);

  const showDiamondDetails = useMemo(() => {
    return {
      'Certificate Link': certificate_link,
      'SCS Certificate Link': scs_certificate_link,
      certification: certification,
      'Diamond Type': source,
      'Carat Weight': carat,
      Shape: shape,
      Cut: cut,
      Color: color,
      Clarity: clarity,
      Measurements: measurement,
      Table: `${table_per ? `${table_per}%` : ''}`,
      Depth: `${depth ? `${depth}%` : ''}`,
      Symmetry: symmetry,
      Polish: polish,
      Girdle: girdle,
      Culet: culet,
      Fluorecence: fluorecence
    };
  }, [diamond.details]);

  const showRingDetails = useMemo(() => {
    const isMetalNotPT = (CrownMetalName ?? MetalName)?.toLowerCase() !== "platinum";
    return {
      'Ring Style': RingStyleName,
      'Ring Crown': RingCrownName,
      'Ring Side': RingSideName, ...(ring_tcw && { 'Minimum Setting Total Weight': `${ring_tcw.toFixed(2)} ct.` }), ...(customisationAllowed && { 'Crown Metal': `${CrownMetalName} ${CrownColorName ? CrownColorName : ''} ${CrownMetalName?.toLowerCase() !== 'platinum' ? 'Gold' : ''}` }),
      'Metal': `${MetalName} ${ColorName ? ColorName : ''} ${MetalName?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`,
      "Crown Metal": `${CrownMetalName ?? MetalName} ${isMetalNotPT ? (CrownColorName ?? ColorName) : ""} ${isMetalNotPT ? "Gold" : ""}`,
      'Ring Size': RingSizeName, ...(EngravingName && { Engraving: EngravingName })
    };
  }, [ring.selectedRingDetails, ring.ringPrice, customisationAllowed]);

  const FirstBandMetalColor = useMemo(() => {
    return weddingButton.find(btn => btn.Code === (Single_First_Metal_Band || Double_First_Metal_Band))?.Name;
  }, [weddingButton, Single_First_Metal_Band, Double_First_Metal_Band]);

  const FirstBand = useMemo(() => {
    return {
      ...(Wedding_Band !== 'No Band' && bands_tcw && { 'Minimum Band Total Weight': Wedding_Band === 'Single' ? `${bands_tcw.toFixed(2)} ct.` : `${(bands_tcw / 2).toFixed(2)} ct.` }),
      Metal: FirstBandMetalColor ? `${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}` : '', ...(First_Band_Engraving && { engraving: First_Band_Engraving })
    };
  }, [Wedding_Band, ring.ringPrice, FirstBandMetalColor, First_Band_Engraving]);

  const SecondBandMetalColor = useMemo(() => {
    return weddingButton.find(btn => btn.Code === Double_Second_Metal_Band)?.Name;
  }, [weddingButton, Double_Second_Metal_Band]);

  const SecondBand = useMemo(() => {
    return {
      ...(Wedding_Band === 'Double' && bands_tcw && {
        'Minimum Band Total Weight': `${(bands_tcw / 2).toFixed(2)} ct.`
      }),
      Metal: SecondBandMetalColor ? `${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}` : '', ...(Second_Band_Engraving && { engraving: Second_Band_Engraving })
    };
  }, [Wedding_Band, ring.ringPrice, SecondBandMetalColor, Second_Band_Engraving]);

  const handleDiamondAction = useCallback(() => {
    setShowDiamondType((type) => !type);
  }, [setShowDiamondType]);

  const handleRingAction = useCallback(() => {
    setShowRingType((type) => !type);
  }, [setShowRingType]);

  // Function of Finalize or Update Product Data
  const handleReviewClick = async () => {
    try {
      let editUUID;
      setIsLoading(true);
      setIsDisabledBtn(true);
      const endpoint = process.env.REACT_APP_DESIGN_DATA_UPLOAD as string;
      const ringSku = `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}-${(Crown_Metal ?? Metal)}-${Metal}-${Color}-${Diamond_Type}`;
      const {
        diamond_details,
        setting_details,
        ring_options_code,
        name,
        sku,
        diamond_filter_options,
        setting_filter_options,
        ring_b2c_price,
        ring_b2b_cost,
        table_row_id,
        ring_extra_data
      } = stateManger.editData;

      const oldEditData = {
        diamond_details, ring_options_code, sku, setting_filter_options, ring_b2b_cost, table_row_id
      };

      const newNames: { [key: string]: string } = {
        ring_name: generateName('ringName') as string,
        diamond_name: generateName('diamondName') as string,
        setting_name: generateName('settingName') as string
      };

      if (Wedding_Band === 'Single') {
        newNames['left_band_name'] = generateName('leftBandName') as string;
      } else if (Wedding_Band === 'Double') {
        newNames['left_band_name'] = generateName('leftBandName') as string;
        newNames['right_band_name'] = generateName('rightBandName') as string;
      }

      const filteredExpandedRow = Object.fromEntries(Object.entries(table.expandRow).filter(([_, value]) => value !== null && value !== ''));

      const newEditData = {
        diamond_details: diamond.details,
        ring_options_code: ring.options,
        sku: ringSku,
        setting_filter_options: {
          ...settingFilter.options,
          maximumCaratSize: settingFilter.maximumCaratSize,
          selectedProduct: settingFilter.selectedProduct
        },
        ring_b2b_cost: 111,
        table_row_id: filteredExpandedRow
      };

      const style = footer.styleID.includes('RP') ? `${footer.styleID}-${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}` : `${footer.styleID}-${Ring_Style}${Ring_Crown}-${Diamond_Shape}-${Center_Diamond_Size}-${Ring_Side}`;
      const uniqueName = footer.styleID.includes('RP') ? `${footer.styleID}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}` : `${footer.styleID}${Ring_Style}${Ring_Crown}${Diamond_Shape}${Center_Diamond_Size}${Ring_Side}${headColor}${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `${Double_Second_Metal_Band?.split('_')[1]}` : ''}${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}`;
      const isSameObjects = compareObjects(oldEditData, newEditData);
      let returnUrl: string = '';
      if (!isSameObjects && !cart.isOrderExist) {
        const result = await fetch(endpoint, {
          method: 'POST', body: JSON.stringify({
            ...newEditData,
            names: newNames,
            setting_details: {
              ...ring.selectedRingDetails,
              Ring_Metal_Color: showRingDetails['Metal']?.trim(),
              Ring_Crown_Metal_Color: showRingDetails['Crown Metal']?.trim(),
              ...{ Ring_First_Band_Metal: FirstBand.Metal ? FirstBand.Metal?.trim() : '' }, ...{ Ring_Second_Band_Metal: SecondBand.Metal ? SecondBand.Metal?.trim() : '' }
            },
            ring_extra_data: { ...ring.extraData, exclusionOption: ring.exclusionOption },
            image_code: `${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/${Wedding_Band === 'Double' ? 'C1' : Wedding_Band === 'Single' ? 'C2' : 'C3'}/`,
            image_urls: {
              'Primary': image[0],
              'Through Finger': image[1],
              'Top': image[2],
              'Side': image[3],
              'Hand': image[4], ...(Wedding_Band !== 'No Band' ? { 'Only Ring': `${process.env.REACT_APP_IMAGE_KEY}/${style}/${headColor}-${Color}${(Single_First_Metal_Band || Double_First_Metal_Band) ? `-${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}` : ''}${Double_Second_Metal_Band ? `-${Double_Second_Metal_Band?.split('_')[1]}` : ''}/C3/V1/2000/fafafa/hq/${uniqueName}V12000.jpg?${image_cache_param}` } : {}), ...(Wedding_Band !== 'No Band' ? { 'Left Band': `${process.env.REACT_APP_IMAGE_KEY}/${style}/${(Single_First_Metal_Band || Double_First_Metal_Band)?.split('_')[1]}/C4/V1/2000/fafafa/hq/${uniqueName}V12000.jpg?${image_cache_param}` } : {}), ...(Wedding_Band === 'Double' ? { 'Right Band': `${process.env.REACT_APP_IMAGE_KEY}/${style}/${(Double_Second_Metal_Band)?.split('_')[1]}/C4/V1/2000/fafafa/hq/${uniqueName}V12000.jpg?${image_cache_param}` } : {})
            },
            ring_b2c_price: +ring_price,
            diamond_filter_options: {
              options: filter.options,
              afterRingSelectedOptions: filter.afterRingSelectedOptions
            },
            action: builder_mode === 'edit' ? 'update' : 'insert', ...(builder_mode === 'edit') && { short_uuid: uuid }
          })
        });
        if (result.ok) {
          let design_data;
          design_data = await result.json();
          if (design_data) {
            if (builder_mode === 'build') {
              editUUID = await design_data.short_uuid;
              returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${await design_data.short_uuid}${hbuuid ? `&uuid=${hbuuid}`:""}`;
            } else if (builder_mode === 'edit') {
              const uuid = JSON.parse(localStorage.getItem('design_uuid') as string);
              editUUID = uuid;
              returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${uuid}${hbuuid ? `&uuid=${hbuuid}`:""}&action=updated`;
            }
          }
        }
      }
      if (isSameObjects || cart.isOrderExist) {
        editUUID = uuid;
        returnUrl = `${window.location.origin}${process.env.REACT_APP_REDIRECT_URL_PREFIX}pid=${rpid}&hkuuid=${uuid}${hbuuid ? `&uuid=${hbuuid}`:""}`;
      }
      ['rpid', 'builder_mode', 'design_uuid'].forEach(key => localStorage.removeItem(key));
      const url = `${window.location.origin}${process.env.REACT_APP_EDIT_URL_PREFIX}#edit/rpid=${rpid}&hkuuid=${editUUID}&start_with=summary&tid=${Date.now()}`;
      window.history.pushState('back-url', '', url);
      window.location.href = returnUrl;
    }catch (e: any) {
      console.error(e.message)
      setIsLoading(false);
      setIsDisabledBtn(false);
    }
  };
  // Generating Name of All Ring Parts
  const generateName = useCallback((name: string) => {
    let DiamondSize = ring.optionsData.Center_Diamond_Size && ring.optionsData.Center_Diamond_Size.find((size: any) => size.Code === Center_Diamond_Size);
    let centerDiamondSize = (diamond.details && diamond.details.carat) ? diamond.details.carat : DiamondSize !== undefined ? DiamondSize.Code / 100 : Center_Diamond_Size;
    if (Object.keys(ring.selectedRingDetails).length > 0) {
      switch (name) {
        case 'ringName':
          return `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${RingStyleName} ${source} Diamond ${DiamondLabels[(shape ? shape : DiamondShapeName) as string]} Engagement Ring${Wedding_Band !== 'No Band' ? Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''}`.trim();
        // return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${RingStyleName} ${DiamondTypeName} Diamond ${DiamondLabels[Diamond_Shape as string]} Engagement Ring in ${MetalName}${ColorName ? ` ${ColorName}` : ''}${MetalName?.toLowerCase() !== "platinum" ? ' Gold' : ''} (${ring_tcw ? (+ring_tcw + (+centerDiamondSize)) : centerDiamondSize} ct. tw.) ${Wedding_Band !== 'No Band' ? Wedding_Band === 'Single' ? 'with Wedding Band' : 'with Wedding Bands' : ''}`.trim();

        case 'diamondName':
          return `${carat} ct. ${DiamondLabels[shape as string]} ${source} Diamond`;

        case 'settingName':
          return `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${RingStyleName} ${source} Diamond ${DiamondLabels[shape as string]} Engagement Ring in ${MetalName}${ColorName ? ` ${ColorName}` : ''}${MetalName?.toLowerCase() !== 'platinum' ? ' Gold' : ''} (${ring_tcw ? (+ring_tcw + (+centerDiamondSize)).toFixed(2) : (+centerDiamondSize).toFixed(2)} ct. tw.)`.trim();
        // return `${(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}${RingStyleName} ${source} Diamond ${DiamondLabels[shape as string]} Engagement Ring in ${MetalName}${ColorName ? ` ${ColorName}` : ''}${MetalName?.toLowerCase() !== "platinum" ? ' Gold' : ''}`.trim()

        case 'leftBandName':
          return `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${source} Diamond Wedding Band in ${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`.trim();

        case 'rightBandName':
          return `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${source} Diamond Wedding Band in ${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`.trim();

        default:
          return '';
      }
    }
  }, [ring.selectedRingDetails, ring.options, ring.optionsData, ring.ringPrice, diamond.details]);

  const editDiamondAction = useCallback(() => {
    toggleScrollTop(true);
    setFooterToggle();
    setSettingTabIconTrue();
    setRedirectEditPage(true);
    setBackToSelectDiamondPage(true);
    document.body.classList.add('sticky_filter');
    const { Diamond_Shape, Diamond_Type } = ring.options;
    if (ring.extraData.Label && ring.extraData.Label !== '' && !Object.keys(filter.options).includes('source')) {
      if (ring.extraData.Label !== 'Generic') {
        setFilter('source', 'LGN');
        const Options: { [key: string]: string | boolean | string[] } = {
          source: Diamond_Type, shape: Diamond_Shape,
          // lab: ['GCAL']
        };
        ring.extraData.Label === 'Reve' && (Options['scs'] = true);
        setAfterRingOptions(Options as { [key: string]: string | string[] });
      }
    }
    (screenManager as any).changeScreen({ viewName: 'diamond', id: null });
  }, [document.body, toggleScrollTop, setFooterToggle, setSettingTabIconTrue, setRedirectEditPage, screenManager, setBackToSelectDiamondPage, ring.extraData, filter.options, setFilter, setAfterRingOptions, ring.options]);

  const editRingAction = useCallback(() => {
    setFooterToggle();
    const screen = footer.styleID.includes('RP') ? 'setting' : 'dyo';
    setDiamondTabIconTrue(screen);
    setRedirectEditPage(true);
    if (Object.keys(diamond.details).length > 0) {
      (screenManager as any).changeScreen({
        viewName: screen, styleId: footer.styleID, id: diamond.details.id
      });
    } else {
      (screenManager as any).changeScreen({
        viewName: screen, styleId: footer.styleID, id: null
      });
    }
  }, [setFooterToggle, setDiamondTabIconTrue, setRedirectEditPage, screenManager, diamond.details, footer.styleID]);

  const handleDoubleFirstBandAction = useCallback(() => {
    setIsShowDoubleFirstBandType((type) => !type);
  }, [setIsShowDoubleFirstBandType]);

  const handleDoubleSecondBandAction = useCallback(() => {
    setIsShowDoubleSecondBandType((type) => !type);
  }, [setIsShowDoubleSecondBandType]);

  const allDetails = [{
    showDetails: Object.keys(diamond.details).length > 0 ? showDiamondDetails : [],
    title: `${carat} ct. ${DiamondLabels[shape as string]} ${source} Diamond`.trim(),
    price: `$${ToLocalStringConvert(diamond_b2c_price ? +diamond_b2c_price : 0)}`, ...(!cart.isOrderExist && { editAction: editDiamondAction }),
    moreAction: handleDiamondAction,
    isShowMore: isShowDiamondType
  }, {
    showDetails: Object.keys(ring.selectedRingDetails).length > 0 ? showRingDetails : [],
    title: `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${RingStyleName} ${source} Diamond Setting in ${MetalName} ${ColorName ? ColorName : ''} ${MetalName?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`.trim(),
    price: `$${ToLocalStringConvert(+ring_price - +left_band_price - +right_band_price)}`, ...(!cart.isOrderExist && { editAction: editRingAction }),
    moreAction: handleRingAction,
    isShowMore: isShowRingType
  }, {
    showDetails: Double_First_Metal_Band || Single_First_Metal_Band ? FirstBand : [],
    title: `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${source} Diamond Wedding Band in
      ${FirstBandMetalColor} ${FirstBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`.trim(),
    price: `$${ToLocalStringConvert(+left_band_price)}`, ...(!cart.isOrderExist && { editAction: editRingAction }),
    moreAction: handleDoubleFirstBandAction,
    isShowMore: isShowDoubleFirstBandType
  }, {
    showDetails: Double_Second_Metal_Band ? SecondBand : [],
    title: `${(Label?.toLowerCase() !== 'generic' && Label) ? ((Label === 'Reve') ? 'rêve ' : `${Label} `) : ''}${source} Diamond Wedding Band in
      ${SecondBandMetalColor} ${SecondBandMetalColor?.split(' ')[0]?.toLowerCase() !== 'platinum' ? 'Gold' : ''}`.trim(),
    price: `$${ToLocalStringConvert(+right_band_price)}`, ...(!cart.isOrderExist && { editAction: editRingAction }),
    moreAction: handleDoubleSecondBandAction,
    isShowMore: isShowDoubleSecondBandType
  }];

  useEffect(() => {
    if (footer.toggle && footer.isShowFooter) {
      const cartView = document.querySelector('#cartView') as HTMLElement;
      if (cartView) {
        const firstTabbableElement = cartView.querySelector<HTMLElement>('.swiper-wrapper [tabindex]:not([tabindex="-1"])');
        if (firstTabbableElement) {
          setTimeout(() => firstTabbableElement.focus(), 200);
        }
      }
    }
  }, [cart.isCartLoading, footer.toggle, footer.isShowFooter, document.querySelector('#cartView')]);

  return {
    rpid, uuid, builder_mode, allDetails, diamond_b2c_price, handleReviewClick, ring_price, Label, isDisabledBtn, isLoading
  };
};

export default useCart;
