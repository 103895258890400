import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import classes from './Slider.module.css';
import { SlickSliderProps } from '../../types';
import Icon from '../Icons/Icon';

const SlickSlider: React.FC<SlickSliderProps> = (props) => {
  const {
    isStaticImage,
    contents,
    handleFilterType,
    filter,
    removeFilterKey,
    labelKey,
    isMultiSelect,
    // setChangeMinMaxValue,
    isNotUpperCase,
    alterSlideConfig,
    isSingleEnable,
    setBackToSelectDiamondPage,
    resetRemovedMinMax,
    exclusionOptions = []
  } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: (contents?.length || 0) >= 4 ? 4 : contents?.length,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const settingsType1 = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 2100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 875,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 610,
        settings: {
          slidesToShow:3,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 340,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
    tabindex?:number;
  }) => {
    const {children, currentSlide, slideCount, ...others} = props;
    return <div {...others}>{children}</div>;
  };
    const sliderSettings = alterSlideConfig ? { ...settingsType1 } : { ...settings };
    const slickRef = useRef<Slider>(null);
    // const buttonFix = useRef<HTMLButtonElement>(null);
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        const findInitialSlideIndex = () => {
            const currentSlideElement = document.querySelector('.slick-slide .currentActiveSlide');
            const parentSlideElement = currentSlideElement?.closest('.slick-slide');
            const dataIndex = parentSlideElement?.getAttribute('data-index');
            const index = dataIndex ? parseInt(dataIndex, 10) : undefined;
            slickRef.current?.slickGoTo(index ?? 0);
        };
        findInitialSlideIndex();
    }, [slickRef]);
    const handleArrowPress =()=>{
        if (slickRef.current && slickRef.current.innerSlider && slickRef.current.innerSlider.list) {
            const slidesAll = slickRef.current.innerSlider.list.querySelectorAll('.slick-slide');

            slidesAll.forEach((slide: Element) => {
                const buttons = slide.querySelectorAll('button');
                buttons.forEach((button: HTMLButtonElement) => {
                    if (slide.classList.contains('slick-active')) {
                        button.tabIndex = 0;
                    } else {
                        button.tabIndex = -1;
                    }
                });
            });
        }
    };
    useEffect(()=>{
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Tab") {
                handleArrowPress();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    },[handleArrowPress]);

  const handleShape = (findFilterShape: string | boolean | string[] | undefined, label: string) => {
    resetRemovedMinMax && resetRemovedMinMax();
   /* if (setChangeMinMaxValue) {
      setChangeMinMaxValue(true);
    }*/
    if(setBackToSelectDiamondPage){
      setBackToSelectDiamondPage(false);
    }
    if (isMultiSelect) {
      if (Array.isArray(filter[labelKey]) && findFilterShape) {
        const filterShape = (filter[labelKey] as string[]).filter(f => f !== label);
        if (filterShape.length === 0) {
          return removeFilterKey(labelKey);
        }
        return handleFilterType(filterShape, labelKey)
      }
      if (filter[labelKey]) {
        return handleFilterType([...filter[labelKey] as string | string[], label], labelKey)
      } else {
        return handleFilterType([label], labelKey)
      }
    }
    if (!isSingleEnable) {
      if (findFilterShape) {
        const filterShape = filter[labelKey];
        if (filterShape === label) {
          return removeFilterKey(labelKey);
        }
        return handleFilterType(label, labelKey)
      }
    }
    handleFilterType(label, labelKey);
  }
  return (
    <div className={classes.root}>
      {/*@ts-ignore*/}
      <Slider
          {...sliderSettings}
          prevArrow={
              <SlickButtonFix>
                  {/*onKeyPress={(e)=> handleArrowPress()}*/}
          <button type={'button'} tabIndex={0} aria-label="prev button">
            <Icon name="hk_chevron_left" className={classes.prevArrow} />
          </button>
              </SlickButtonFix>
        }
        nextArrow={
            <SlickButtonFix>
          <button type={'button'} tabIndex={0} aria-label="Next">
            <Icon name="hk_chevron_right" className={classes.nextArrow} />
          </button>
                </SlickButtonFix>
        }
        afterChange = {handleArrowPress}
        ref={slickRef}
      >
        {contents &&
          contents.map((content, index: number) => {
            const findFilterShape = isMultiSelect ? Array.isArray(filter[labelKey]) && (filter[labelKey] as string[]).find(f => f === content.code.toUpperCase()) : Array.isArray(filter[labelKey]) ? (filter[labelKey] as string[])[0] :filter[labelKey];
            return (
              <button
                className={
                  `${findFilterShape === (isNotUpperCase ? content.code : content.code.toUpperCase())
                    ? `${classes.active} currentActiveSlide`
                    : ''} ${classes.content}`
                }
                disabled={exclusionOptions.includes(content.code)}
                key={index}
                type={"button"}
                role={"button"}
                tabIndex={1}
                onClick={() => handleShape(findFilterShape,isNotUpperCase ? content.code : content.code.toUpperCase())}
              >
                  <div className={classes.img}>
                      {/*{isStaticImage ? <img src={content.imgPath}/> : null}*/}
                      {/*<Vector name={content.label?.toLowerCase()}/>*/}
                      <img
                          src={content.imgPath}
                          alt={content.label}
                          /*style={{
                              WebkitMaskImage: `url(${content.imgPath})`,
                              maskImage: `url(${content.imgPath})`,
                              maskSize: 'contain'
                          }}*/
                      />
                  </div>

                <div className={classes.label}>{content.label}</div>
              </button>
            );
          })}
      </Slider>
    </div>
  );
};
export default SlickSlider;
