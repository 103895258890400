import React from 'react';
import classes from './MainImageSlider.module.css';
import Icon from '../Icons';
import Slider from 'rc-slider';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/a11y';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
// import required modules
import { Thumbs } from 'swiper/modules';
import hand3D from '../../Utils/images/handView-3d.png';
import { MainImageSliderProps } from '../../types';
import useMainImageSlider from './useMainImageSlider';
import Loader from '../Loader/Loader';
import LabDialog from '../LabDialog';

interface CustomProperties extends React.CSSProperties {
  "--paddingTop"?: string;
  "--viewMargin"?: string;
}
const MainImageSlider: React.FC<MainImageSliderProps> = (props) => {
  const {
    img,
    is360,
    viewSrc,
    iframeLoadActionCount,
    isLoading = false,
    isHandImage = false,
    isShowGIA = false,
    certification,
    certificationName,
    isHandLoaded,
    isEngraving = false,
    setIsEngraving,
    isDiamondDetail = false,
    showEngraving = false,
    isDisabledThumbnail = false
  } = props;
  /*const mapStateToProps = (state: RootState) => ({
    ring: state.ring
  });*/

  const {
    islargeTab,
    thumbsContainerRef,
    allThumbnailRef,
    swipeSlider,
    getBackgroundColor,
    handleImageError,
    onColorSliderChange,
    handleThumbsResize,
    handleHandViewMobile,
    handleHandView,
    setHandleRange,
    handleRange,
    handleHandto3dView,
    sliderThumbImage,
    modules,
    onSliderChange,
    thumbsSwiper,
    handleEngraving,
    handleEngravingView,
    setThumbsSwiper,
    setSwipeSlider,
    handleContextMenu,
    mainImageSliderRef,
    viewDetailsRandomClass,
    height,
    width,
    handleEngravingPreview,
    isShowColorPlateMobile,
    ref
  } = useMainImageSlider(props);

  const {
    allThumbnailCoordinates,
    imageError,
    handView,
    colorState,
    handViewImage,
    activeIndex
  } = swipeSlider;

  return (
    <div className={`sliderWrapper`}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Swiper
            loop={false}
            onResize={handleThumbsResize}
            pagination={{
              clickable: true,
              enabled: true,
            }}
            keyboard={{ enabled: true }}
            navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={modules}
            className={`${classes.swiperMain} mainSlider`}
            onSlideChange={(swiper) => onSliderChange(swiper)}
            ref={mainImageSliderRef}
          >
            {is360 && viewSrc ? (
              <SwiperSlide>
                <div
                  className={`${classes.iframe} sliderIframe ${
                    handleRange ? "disableFrame" : ""
                  }`}
                >
                  <>
                    {islargeTab && isHandImage && (
                      <div
                        className={`${classes.handViewMobiles} ${
                          handView
                            ? classes.handMobileToggle
                            : classes.view3dMobileToggle
                        }`}
                      >
                        <button
                          tabIndex={0}
                          disabled={isDisabledThumbnail}
                          onClick={() => {
                            showEngraving ? handleHandView() : handleHandViewMobile();
                          }}
                          className={`${showEngraving && ((handView || isShowColorPlateMobile) && isHandLoaded) ? classes.buttonViewChangeDisabled : classes.buttonViewChange}`}
                          role={"button"}
                        >
                          <>
                                <span className={'text-padding-top-15'}>
                                {!handView ? 'Hand View' : <>360<sup>o</sup>{' '}View</>}
                              </span>
                          </>
                        </button>
                      </div>
                    )}
                    {islargeTab && showEngraving &&
                      sliderThumbImage.length > 0 && (
                        <div
                          className={`${classes.handViewMobiles} ${classes.engravingView}`}
                        >
                        <button
                          disabled={isDisabledThumbnail}
                          className={`${classes.buttonViewChange} `}
                          onClick={handleEngravingView}
                        >
                          <span className={'text-padding-top-15'}>
                            {isEngraving ? <>360<sup>o</sup>{' '}View</> : 'Engraving View'}
                          </span>
                        </button>
                        </div>
                      )}
                    {isDiamondDetail ? (
                      <div
                        className={viewDetailsRandomClass}
                        style={
                          {
                            "--paddingTop": `${((height / width) * 100).toFixed(
                              0
                            )}%`,
                            "--viewMargin": `${
                              (100 - +((height / width) * 100)) / 2
                            }%`,
                          } as CustomProperties
                        }
                      >
                        <div className={classes.viewIframeWrapper}>
                          <div className={classes.viewIframeInner}>
                            <div className={classes.viewIframeItem}>
                              <iframe
                                src={viewSrc}
                                className={`${classes.viewIframe} ${certificationName}`}
                                scrolling={"no"}
                                tabIndex={-1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <iframe
                        src={viewSrc}
                        ref={ref}
                        className={`${
                          classes.viewIframe
                        } ${certificationName} ${
                          iframeLoadActionCount === 0 ? "block" : "hidden"
                        }`}
                        scrolling={"no"}
                        tabIndex={-1}
                      />
                    )}
                    {isHandImage ? (
                      <>
                        {" "}
                        <span className={classes.frameText}>
                          <span  className="md:hidden block mr-1">Interactive Video –</span> Drag to Rotate
                          <Icon name={"hk-hand"} />
                        </span>
                      </>
                    ) : null}
                  </>
                  {iframeLoadActionCount && iframeLoadActionCount > 0 ? (
                    <Loader ajaxLoad={true} />
                  ) : null}
                </div>
              </SwiperSlide>
            ) : null}
            {img.length > 0
              ? img.map((imgPath: string, index: number) => {
                return (
                  <div key={index} className={classes.mainImages}>
                    {!imageError[index] ? (
                      <SwiperSlide key={index}>
                        {index === img.length - 1 && isHandImage ? (
                          <>
                            <img
                              src={imgPath}
                              className={classes.lightSkin}
                              style={{
                                opacity: `calc(100% - ${colorState}%)`
                              }}
                              alt={'Light skin'}
                            />
                            <img
                              src={imgPath
                                ?.replaceAll('/V5/2000', '/V6/2000')
                                ?.replaceAll('V52000', 'V62000')}
                              className={classes.darkSkin}
                              alt={'Dark skin'}
                            />
                          </>
                        ) : (
                          <img
                            src={`${imgPath}`}
                            className={
                              isDiamondDetail ? classes.testView : ''
                            }
                            alt={'Slider Image'}
                            ref={
                              ref as unknown as React.RefObject<HTMLImageElement>
                            }
                          />
                        )}
                      </SwiperSlide>
                    ) : null}
                  </div>
                );
              })
              : null}
            <div
              className={`${classes.buttonNav} ${
                !islargeTab ? classes.disableButtons : ""
              }`}
            >
              <button className="arrow-left arrow">
                <Icon name={"hk-chevron-left_M"} />
              </button>
              <button className="arrow-right arrow">
                <Icon name={"hk-chevron-right_M"} />
              </button>
            </div>
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={false}
            spaceBetween={10}
            slidesPerView={7}
            freeMode={false}
            observer={true}
            centeredSlides={false}
            observeParents={true}
            watchSlidesProgress={true}
            modules={[Thumbs]}
            ref={allThumbnailRef}
            className={`${classes.swiperThumb} swiperThumb ${
              islargeTab ? "!hidden" : ""
            } ${
              isShowGIA && !!certification ? classes.certificateWithThumb : ""
            }`}
            // classes.disableThumbs
            breakpoints={{
              0: {
                direction: "horizontal",
              },
              1440: {
                direction: "vertical",
              },
            }}
          >
            {is360 && viewSrc && sliderThumbImage.length > 0 ? (
              <SwiperSlide
                className={`${showEngraving ? classes.swiper3dWithEngravingThumb : isHandImage ? classes.swiper3d : ''}`}>
                <button
                  disabled={isDisabledThumbnail}
                  className={`${classes.icon360} ${
                    activeIndex === 0 && !handView && !isEngraving ? classes.active3dView : ''
                  }`}
                  onClick={() => {
                    handleHandto3dView();
                    isEngraving && setIsEngraving && setIsEngraving(false);
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.target instanceof HTMLElement &&
                      event.key === 'Enter'
                    ) {
                      (
                        mainImageSliderRef.current as unknown as {
                          swiper: SwiperType;
                        }
                      ).swiper.slideTo(0);
                      handleHandto3dView();

                    }
                  }}
                  tabIndex={0}
                >
                  <div className={classes.icon360Group}>
                    <Icon name="hk-rotate" />
                    <span className={classes.text360Side}>
                      360<sup>o</sup>
                    </span>
                  </div>
                  <span className={classes.text360}>Experience</span>
                </button>
                {showEngraving ? <button
                  disabled={isDisabledThumbnail}
                  className={`${classes.icon360} ${classes.engravingPreview} ${
                    activeIndex === 0 && isEngraving && !handView ? classes.active3dView : ''
                  }`}
                  onClick={() => {
                    handleEngravingPreview();
                  }}
                  onKeyDown={(event) => {
                    if (
                      event.target instanceof HTMLElement &&
                      event.key === 'Enter'
                    ) {
                      (
                        mainImageSliderRef.current as unknown as {
                          swiper: SwiperType;
                        }
                      ).swiper.slideTo(0);
                      handleEngravingPreview();
                    }
                  }}
                  tabIndex={0}
                >
                  <div className={classes.icon360Group}>
                    <span className={classes.text360Side}>
                      Engraving Preview
                    </span>
                  </div>
                </button> : null}
                {isHandImage && (
                  <button
                    disabled={isDisabledThumbnail}
                    className={`${classes.icon360Hand} ${
                      handView ? classes.activeHand : ''
                    } ${activeIndex === 0 ? classes.actives : ''}`}
                    tabIndex={0}
                    onClick={handleHandView}
                    onKeyDown={(event) => {
                      if (
                        event.target instanceof HTMLElement &&
                        (event.key === 'Enter' ||
                          event.key === 'Spacebar' ||
                          event.key === ' ')
                      ) {
                        (
                          mainImageSliderRef.current as unknown as {
                            swiper: SwiperType;
                          }
                        ).swiper.slideTo(0);
                        handleHandView();
                      }
                    }}
                  >
                    <img src={hand3D} alt={'Hand View'} />
                  </button>
                )}
              </SwiperSlide>
            ) : null}
            {sliderThumbImage.length > 0
              ? sliderThumbImage.map((imgPath: string, index: number) => {
                  return (
                    <div key={index} className={classes.sliderThumbImages}>
                      {!imageError[index] ? (
                        <SwiperSlide
                          key={index}
                          //@ts-ignore
                          ref={thumbsContainerRef}
                          className={
                            !is360 && activeIndex === index
                              ? "swiper-slide-thumb-active"
                              : ""
                          }
                          tabIndex={0}
                          onKeyDown={(event) => {
                            if (
                              event.key === "Enter" ||
                              event.key === "Spacebar" ||
                              event.key === " "
                            ) {
                              (
                                mainImageSliderRef.current as unknown as {
                                  swiper: SwiperType;
                                }
                              ).swiper.slideTo(
                                is360 && viewSrc ? index + 1 : index
                              );
                            }
                          }}
                          onClick={() => {
                            if (index === sliderThumbImage.length - 1) {
                              setSwipeSlider((prevState) => {
                                return {
                                  ...prevState,
                                  handViewImage: true,
                                };
                              });
                            }
                          }}
                        >
                          <img
                            src={`${imgPath}`}
                            alt={`Thumb Image-${index}`}
                            className={
                              imageError[index] ? `${classes.imgNotFound}` : ""
                            }
                            onError={() => handleImageError(index)}
                          />
                          <span
                            className={
                              imageError[index] ? `${classes.notFound}` : ""
                            }
                          />
                        </SwiperSlide>
                      ) : null}
                    </div>
                  );
                })
              : null}
          </Swiper>
        </>
      )}
      {isShowGIA && !!certification && !!certificationName ? (
        <LabDialog
          certificationName={certificationName}
          allThumbnailCoordinates={allThumbnailCoordinates}
          certification={certification}
        />
      ) : null}
      {isHandImage ? (
        <div
          className={`${
            (handView || isShowColorPlateMobile) && isHandLoaded && activeIndex === 0
              ? classes.displayBlock
              : classes.displayHidden
          } ${classes.rangeSlider}`}
          onContextMenu={handleContextMenu}
          style={
            { "--handleBackground": getBackgroundColor } as {
              [key: string]: string;
            }
          }
        >
          <Slider
            vertical
            onChange={() => setHandleRange(true)}
            onChangeComplete={onColorSliderChange}
            min={0}
            max={100}
            defaultValue={0}
          />
        </div>
      ) : null}
    </div>
  );
};

export default MainImageSlider;
