import React, { FC, useEffect, useRef, useState } from "react";
import classes from "./Diamond.module.css";
import Tabs from "../Tabs";
import Filter from "../Filter";
import { RootState } from "../../reducers/RootReducer";
import {
  recommendedDataFail,
  recommendedDataLoading,
  recommendedDataSuccess,
  setActiveTab,
  setExpandedRow,
  setIsRefetching,
  setPagination,
  setRecommendedDataDefault,
  setSorting,
  tableDataFail,
  tableDataLoading,
  tableDataSuccess,
  toggleScrollTop,
} from "../../action/TableAction";
import {
  resetFilters,
  setFilter,
  setFilterMinMaxProperty,
  setFilterSliderRange,
  setInitialFilterAfterRingOptions,
} from "../../action/FilterAction";
import {
  setBackDiamondDetails,
  setInitialOption,
  setRingOptions,
  setSelectedRingDetails,
} from "../../action/RingAction";
import { connect, ConnectedProps } from "react-redux";
import useDiamond from "./useDiamond";
import {
  diamondValue,
  PropsDiamondFilter,
  PropsFromScreenManager,
} from "../../types";
import DiamondFilterContent from "../DiamondFilterContent";
import Loader from "../Loader/Loader";
import {
  SetTooltipDataError,
  setTooltipDataLoading,
  SetTooltipDataSuccess,
} from "../../action/TooltipAction";
import Header from "../Header/Header";
import StickyFooter from "../StickyFooter/StickyFooter";
import { compareTwoDiamondValue } from "../../Utils/campareTwoDiamondValue";
import Icon from "../Icons";
import {
  setBackToSelectDiamondPage,
  setDiamondDetailsSuccess,
} from "../../action/DiamondAction";
import { setIsShowFooter } from "../../action/FooterAction";

const mapStateToProps = (state: RootState) => ({
  table: state.table,
  diamond: state.diamond,
  filter: state.filter,
  ring: state.ring,
  tooltip: state.tooltip,
  footer: state.footer,
});

const mapDispatchToProps = {
  tableDataLoading,
  tableDataSuccess,
  tableDataFail,
  recommendedDataLoading,
  recommendedDataSuccess,
  recommendedDataFail,
  setFilterMinMaxProperty,
  // setChangeMinMaxValue,
  setInitialOption,
  setBackDiamondDetails,
  SetTooltipDataSuccess,
  setTooltipDataLoading,
  SetTooltipDataError,
  resetFilters,
  setInitialFilterAfterRingOptions,
  setActiveTab,
  setDiamondDetailsSuccess,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setIsShowFooter,
  setFilter,
  setIsRefetching,
  setSorting,
  setPagination,
  setBackToSelectDiamondPage,
  setRecommendedDataDefault,
  toggleScrollTop,
  setFilterSliderRange,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PropsFromDiamondRedux = ConnectedProps<typeof connector>;

const Diamond: FC<PropsDiamondFilter & PropsFromScreenManager> = (props) => {
  const { tooltip, instanceData, filter } = props;

  const {
    source,
    tabs,
    color,
    clarity,
    cut,
    fluorecence,
    polish,
    symmetry,
    handleRESETFilters,
    isMobileLayout,
  } = useDiamond(props);
  const scrollRoot = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const div = scrollRoot.current;
    const threshold = 300;
    if (div) {
      const scrollY = div.scrollTop;
      setShowButton(scrollY > threshold);
    }
  };

  useEffect(() => {
    const div = scrollRoot.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (div) {
        div.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  const handleScrollTopClick = () => {
    /*if (scrollRoot.current) {
            scrollRoot.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }*/

    if (scrollRoot.current) {
      const duration = 500; // Adjust duration as needed
      const startTime = performance.now();
      const startScrollTop = scrollRoot.current.scrollTop;
      const easeInOut = (t: number) =>
        t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

      const animateScroll = (currentTime: number) => {
        const elapsedTime = currentTime - startTime;
        const fraction = Math.min(1, elapsedTime / duration);
        const scrollToTop =
          startScrollTop - startScrollTop * easeInOut(fraction);

        if (scrollRoot.current) {
          scrollRoot.current.scrollTop = scrollToTop;
        }

        if (elapsedTime < duration) {
          requestAnimationFrame(animateScroll);
        }
      };
      requestAnimationFrame(animateScroll);
    }
  };
  if (!(tabs && tabs.length > 0) || tooltip.isLoading) {
    return (
      <div style={{ height: "100vh" }}>
        <Loader position={"absolute"} />
      </div>
    );
  }
  const selectedDiamondValue: diamondValue = {
    carat: filter.options.carat as string,
    source: filter.options.source as string,
    shape: filter.options.shape as string[],
  };
  return (
    <>
      <Header instanceData={instanceData} />
      <main
        className={`${classes.wrapper} ${showButton ? "scrollTopVisible" : ""}`}
        ref={scrollRoot}
      >
        <Filter
          isShowFilter={
            isMobileLayout ? true : Object.keys(filter.minMaxValue).length > 0
          }
          isSetHeight={true}
          isDisabledFilter={!(Object.keys(filter.minMaxValue).length > 0)}
          // isReset={Object.keys(filter.options).length > (Object.keys(filter.afterRingSelectedOptions).length > 0 ? 3: 0)}
          isReset={
            Object.keys(filter.afterRingSelectedOptions).length > 0
              ? !compareTwoDiamondValue(
                  selectedDiamondValue,
                  filter.afterRingSelectedOptions
                ) ||
                Object.keys(filter.afterRingSelectedOptions).length !==
                  Object.keys(filter.options).length
              : Object.keys(filter.options).length > 0
          }
          handleRESETFilters={handleRESETFilters}
          isMobileLayout={isMobileLayout}
        >
          <DiamondFilterContent
            source={source}
            color={color}
            clarity={clarity}
            cut={cut}
            fluorecence={fluorecence}
            polish={polish}
            symmetry={symmetry}
          />
        </Filter>
        <div className={classes.tabs}>
          <Tabs tabs={tabs} />
        </div>
        <button
          onClick={handleScrollTopClick}
          onKeyUp={(event) => {
            if (event.target instanceof HTMLElement && event.key === "Enter") {
              handleScrollTopClick();
              const tabNavigationElement =
                document.getElementById("tabNavigation");
              if (
                tabNavigationElement &&
                document.querySelector("body.tabbing")
              ) {
                const childNodesArray = Array.from(
                  tabNavigationElement.childNodes
                ) as Node[];
                const firstTabbableSibling = childNodesArray.find((child) => {
                  return child instanceof HTMLElement && child.tabIndex === 0;
                }) as HTMLElement | undefined;

                if (firstTabbableSibling) {
                  event.stopPropagation();
                  firstTabbableSibling.focus();
                  event.preventDefault();
                }
              }
            }
          }}
          className={`stickyButton ${showButton ? "active" : ""} ${
            classes.topToBottomBtn
          }`}
          aria-label={"Scroll to top"}
        >
          <span className={"iconTop"}>
            <Icon name={"hk-arrow-up-forward"} />
          </span>
        </button>
      </main>
      <StickyFooter instanceData={instanceData} />
    </>
  );
};

export default connector(Diamond);
