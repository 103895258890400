import React, {FC} from "react";
import classes from "./StickyFooter.module.css";
import Cart from "../Cart";
import { RootState } from "../../reducers/RootReducer";
import { setFooterToggle,setFooterHeight } from "../../action/FooterAction";
import { connect, ConnectedProps } from "react-redux";
import useStickyFooter from "./useStickyFooter";
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {PropsFromScreenManager} from '../../types';
import {setSelectedRingDetails} from '../../action/RingAction';
import {DiamondLabels} from "../../Utils/commonData";

const mapStateToProps = (state: RootState) => ({
  footer: state.footer,
  diamond: state.diamond,
  ring: state.ring,
  filter: state.filter,
});
const mapDispatchToProps = {
  setFooterToggle,
  setFooterHeight,
  setSelectedRingDetails
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromFooterRedux = ConnectedProps<typeof connector>;
const StickyFooter: FC<PropsFromFooterRedux & PropsFromScreenManager> = ({
  diamond,
  ring,
  footer,
  setFooterToggle,
  setFooterHeight,
  filter,
  instanceData,
  setSelectedRingDetails
}) => {
  const { wrapperRef, handleClick } = useStickyFooter({
    setFooterToggle,
    setFooterHeight,
    diamond,
    footer,
    ring,
    filter,
    setSelectedRingDetails
  });
  const toggledClass = footer.toggle ? classes.active : "";
  const { carat, shape, source, b2c_price:diamond_b2c_price } = diamond.details;
  const { Ring_Style,Metal,Color} = ring.selectedRingDetails;
  const {ring_price, left_band_price, right_band_price} = ring.ringPrice;
  const {Label} =ring.extraData;
  const ringPrice = +ring_price /*+ (ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? +left_band_price : +(left_band_price+right_band_price)  :0)*/;

  return (
    <>
      {footer.isShowFooter
        ? (
        <div className={classes.root}>
          <div
            className={`${classes.curtain} ${
              footer.toggle ? classes.isOpen : ""
            }`}
          >
            <Cart instanceData={instanceData}/>
          </div>
          {(!(Object.keys(diamond.details).length > 0 && Object.keys(ring.options).length > 0 && footer.toggle)) && <div className={classes.fluide}>
            <div ref={wrapperRef} className={classes.wrapper}>
              {/*<div className={classes.arrow}>
                <Button
                  type={"button"}
                  buttonType="btnIcon"
                  className={`${classes.button} ${toggledClass}`}
                  onClick={handleClick}
                >
                  <Icon name="hk_chevron_up" />
                </Button>
              </div>*/}
              <div className={classes.content}>
                <h3 className={classes.title}>Your Engagement Ring: <span className="text-padding-top">{ring.isPriceLoading ? "Loading..." :
                  (Object.keys(ring.options).length > 0 && ringPrice)
                    ? diamond_b2c_price
                      ? `$${ToLocalStringConvert(+diamond_b2c_price + +ringPrice)}`
                      : `$${ToLocalStringConvert(+ringPrice)}`
                    : diamond_b2c_price
                      ? `$${ToLocalStringConvert(+diamond_b2c_price)}`
                      : ''
                }</span>
                </h3>
                <ul className={classes.detailList}>
                  {Object.keys(diamond.details).length > 0 ? <li className={classes.detail}>
                    {carat} ct. {DiamondLabels[shape as string]} {source} Diamond {" "}
                    <span className={classes.price}>${ToLocalStringConvert(diamond_b2c_price?+diamond_b2c_price:0)}</span>
                  </li> : ''}
                  {Object.keys(ring.selectedRingDetails).length > 0 ? <li className={classes.detail}>
                    {/*{ring.extraData.Label?.toLowerCase() !== "generic" ? ring.extraData.Label : null} {Ring_Style} {footer.styleID?.includes('RB') ? `${ Metal === 'Platinum' ? Metal : Metal.split(' ')[0]} ${Color} ${Metal.split(' ')[1]} Ring` : `Setting`} {ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''} {" "}*/}
                    {(Label?.toLowerCase() !== "generic" && Label) ? ((Label === 'Reve') ? 'rêve ':`${Label} `) : ''}{Ring_Style} Setting {ring.options.Wedding_Band !== 'No Band' ? ring.options.Wedding_Band === 'Single' ? ' with Wedding Band' : ' with Wedding Bands' : ''} {" "}
                    {ring.isPriceLoading ? <span className={classes.price}>Loading...</span> :<span className={classes.price}>${ToLocalStringConvert(+ringPrice)}</span>}
                  </li> : ''}
                </ul>
              </div>
                <div className={classes.total}>
                    <span className="text-padding-top">
                        {/*{ring.isPriceLoading ? "Loading..." :*/}
                        {/*  (Object.keys(ring.options).length > 0 && ringPrice)*/}
                        {/*  ? diamond_b2c_price*/}
                        {/*  ? `$${ToLocalStringConvert(+diamond_b2c_price + +ringPrice)}`*/}
                        {/*  : `$${ToLocalStringConvert(+ringPrice)}`*/}
                        {/*  : diamond_b2c_price*/}
                        {/*  ? `$${ToLocalStringConvert(+diamond_b2c_price)}`*/}
                        {/*  : ''*/}
                        {/*}*/}
                    </span>
                </div>
            </div>
          </div>}
        </div>
      ) : null}
    </>
  );
};

export default connector(StickyFooter);
