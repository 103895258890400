import React, { FC, useEffect } from "react";
import classes from "./diamondFilterContent.module.css";
import Label from "../Label/Label";
import Button from "../Button";
import SlickSlider from "../Slider";
import InputRangeSlider from "../InputSlider";
import LabelRangeSlider from "../LabelRangeSlider";
import useDiamondFilterContent from "./useDiamondFilterConent";
import { FilterContentType, sliderRangeProps } from "../../types";
import { RootState } from "../../reducers/RootReducer";
import {
  removeFilterKey,
  resetRemovedMinMax,
  setChangeMinMaxValue,
  setFilter,
  setRemovedMinMaxOptions,
} from "../../action/FilterAction";
import {
  setPriceLoading,
  setRingOptions,
  setRingPrice,
  setRingPriceError,
} from "../../action/RingAction";
import { setBackToSelectDiamondPage } from "../../action/DiamondAction";
import { connect, ConnectedProps } from "react-redux";
import { DiamondShapeContent } from "../../Utils/commonData";
import { useMediaQuery } from "react-responsive";
import { toggleScrollTop } from "../../action/TableAction";

const mapStateToProps = (state: RootState) => ({
  filter: state.filter,
  ring: state.ring,
  footer: state.footer,
  settingFilter: state.settingFilter,
});

const mapDispatchToProps = {
  setFilter,
  removeFilterKey,
  setChangeMinMaxValue,
  setPriceLoading,
  setRingPrice,
  setRingPriceError,
  setRingOptions,
  setBackToSelectDiamondPage,
  setRemovedMinMaxOptions,
  resetRemovedMinMax,
  toggleScrollTop,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromDiamondFilterContentRedux = ConnectedProps<
  typeof connector
>;

const DiamondFilterContent: FC<FilterContentType> = (props) => {
  const {
    ring,
    settingFilter,
    setChangeMinMaxValue,
    source,
    removeFilterKey,
    filter,
    color,
    clarity,
    cut,
    fluorecence,
    polish,
    symmetry,
    setBackToSelectDiamondPage,
    setRemovedMinMaxOptions,
    resetRemovedMinMax,
    toggleScrollTop,
  } = props;

  const {
    handleFilterType,
    SCS,
    setSCS,
    tooltipPadding,
    handleChangeDiamondType,
  } = useDiamondFilterContent(props);

  const rpid = JSON.parse(localStorage.getItem("rpid") as string);
  const builder_mode = JSON.parse(
    localStorage.getItem("builder_mode") as string
  );
  const uuid = JSON.parse(localStorage.getItem("design_uuid") as string);
  const isEditBuilder = rpid && uuid && builder_mode === "edit";

  const isLargeScreen = useMediaQuery({ query: "(max-width: 1575px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });
  const maxCaratSize = +settingFilter.maximumCaratSize / 100;

  useEffect(() => {
    // classes.items
    const handleTabPress = (event: KeyboardEvent) => {
      const section8Parent = document.querySelector(
        `.${classes.items}`
      )?.parentNode;
      const getFilterButton = window.document.querySelector(
        '[name="filterButton"]'
      ) as HTMLButtonElement;
      const checkItemScreenBased = isTablet ? 5 : isLargeScreen ? 3 : 7;
      const section8 =
        section8Parent && section8Parent.children[checkItemScreenBased];
      if (section8) {
        const tabbableElements = Array.from(
          section8.querySelectorAll(
            'input, button, textarea, select, a[href], [tabindex]:not([tabindex="-1"])'
          )
        );
        const lastTabbableElement = tabbableElements[
          tabbableElements.length - 1
        ] as HTMLElement;
        if (
          !event.shiftKey &&
          event.key === "Tab" &&
          document.activeElement === lastTabbableElement &&
          getFilterButton.className.match(/\bmore_filter\b/)
        ) {
          event.preventDefault();
          getFilterButton.focus();
        } else if (
          event.shiftKey &&
          event.key === "Tab" &&
          document.activeElement === getFilterButton
        ) {
          if (getFilterButton.className.match(/\bmore_filter\b/)) {
            event.preventDefault();
            lastTabbableElement.focus();
          }
        }
      }
    };

    document.addEventListener("keydown", handleTabPress);

    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  });

  return (
    <>
      {filter.minMaxValue && Object.keys(filter.minMaxValue).length > 0 ? (
        <>
          {/*{!filter.afterRingSelectedOptions.source && */}
          <div className={classes.items}>
            <Label text={"Center Diamond Type"}>
              {source.map((name) => {
                return (
                  <Button
                    key={name}
                    type="button"
                    className={classes.button}
                    buttonType={
                      filter.options?.source &&
                      (name === "Natural" ? "GNU" : "LGN") ===
                        filter.options.source
                        ? "active"
                        : undefined
                    }
                    isDisabled={
                      (name === "Natural" &&
                        !!ring.extraData.Label &&
                        !["Generic"].includes(ring.extraData.Label)) ||
                      (name !== "Natural" &&
                        ["Jenny Packham", "Reve"].includes(
                          ring.extraData.Label
                        ))
                    }
                    onClick={() => handleChangeDiamondType(name)}
                  >
                    <span className="text-padding-top-7">{name}</span>
                  </Button>
                );
              })}
            </Label>
          </div>
          {/*}*/}
          {/*{!filter.afterRingSelectedOptions.shape && */}
          <div className={classes.items}>
            <Label text={"Center Diamond Shape"}>
              <SlickSlider
                contents={DiamondShapeContent}
                removeFilterKey={removeFilterKey}
                exclusionOptions={ring.exclusionOption as string[]}
                handleFilterType={handleFilterType}
                filter={filter.options}
                labelKey={"shape"}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                isMultiSelect={false}
                isSingleEnable={false}
                resetRemovedMinMax={resetRemovedMinMax}
              />
            </Label>
          </div>
          {/*}*/}
          {/*{!filter.afterRingSelectedOptions.carat &&*/}{" "}
          <div className={classes.items}>
            <Label text={"Carat"} tooltipPadding={tooltipPadding["carat"]}>
              <InputRangeSlider
                min={+filter.minMaxValue.carat.total_min_value}
                max={
                  maxCaratSize
                    ? +maxCaratSize.toFixed()
                    : +filter.minMaxValue.carat.total_max_value
                }
                selectedValue={
                  !filter.removedMinMax["carat"]
                    ? [
                        +filter.minMaxValue.carat.min_value,
                        maxCaratSize &&
                        maxCaratSize < +filter.minMaxValue.carat.max_value
                          ? +maxCaratSize
                          : +filter.minMaxValue.carat.max_value,
                      ]
                    : undefined
                }
                // rangeValue={isEditBuilder ? (filter.options.carat as string)?.split('-') : ((filter.afterRingSelectedOptions.carat as string)?.split('-') || (filter.options.carat as string)?.split('-'))}
                rangeValue={(filter.options.carat as string)?.split("-")}
                step={0.01}
                isShowDollar={false}
                keyName={"carat"}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                options={filter.options}
                isReset={filter.isReset}
                setRemovedMinMaxOptions={setRemovedMinMaxOptions}
                isCustomSlider={true}
                sliderRange={
                  filter.sliderRange
                    .caratSliderRange as unknown as sliderRangeProps[]
                }
              />
            </Label>
          </div>
          {/*}*/}
          <div className={classes.items}>
            <Label text={"Diamond Price"}>
              <InputRangeSlider
                min={+filter.minMaxValue.b2c_price.total_min_value}
                max={+filter.minMaxValue.b2c_price.total_max_value}
                selectedValue={
                  !filter.removedMinMax["diamond_price"]
                    ? [
                        +filter.minMaxValue.b2c_price.min_value,
                        +filter.minMaxValue.b2c_price.max_value,
                      ]
                    : undefined
                }
                rangeValue={(filter.options.diamond_price as string)?.split(
                  "-"
                )}
                step={1}
                isShowDollar={true}
                keyName={"diamond_price"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                options={filter.options}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                isReset={filter.isReset}
                setRemovedMinMaxOptions={setRemovedMinMaxOptions}
                isCustomSlider={true}
                sliderRange={
                  filter.sliderRange
                    .priceSliderRange as unknown as sliderRangeProps[]
                }
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Color"} tooltipPadding={tooltipPadding["color"]}>
              <LabelRangeSlider
                mark={color}
                keyName={"color"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.color as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Cut"}>
              <LabelRangeSlider
                mark={cut}
                keyName={"cut"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.cut as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Clarity"} tooltipPadding={tooltipPadding["clarity"]}>
              <LabelRangeSlider
                mark={clarity}
                keyName={"clarity"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.clarity as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label
              text={"Certification"}
              endSideContent={
                filter.options?.source !== "GNU" ? (
                  <div
                    className={`${classes.checkmark} cursor-pointer`}
                    onClick={() => {
                      if (!(ring.extraData.Label === "Reve")) {
                        setSCS((scs) => !scs);
                        handleFilterType(!SCS, "scs");
                      }
                    }}
                  >
                    <input
                      type={"checkbox"}
                      id={"scs-007"}
                      checked={ring.extraData.Label === "Reve" ? true : SCS}
                      disabled={ring.extraData.Label === "Reve"}
                      aria-label={"scs-007 Certificate"}
                      readOnly={true}
                    />
                    <Label text={"SCS-007"}>
                      <label
                        htmlFor={"scs-007"}
                        className={
                          "cursor-pointer invisible max-w-0 max-h-0 inline overflow-hidden"
                        }
                      >
                        scs-007
                      </label>
                    </Label>
                  </div>
                ) : null
              }
            >
              {["GIA", "IGI", "GCAL"].map((name) => {
                const findLab =
                  Array.isArray(filter.options?.lab) &&
                  filter.options?.lab.find((f) => f === name.toUpperCase());
                return (
                  <Button
                    key={name}
                    type="button"
                    className={`${classes.button} ${
                      name === "GCAL" ? "gcal_button" : ""
                    }`}
                    buttonType={name === findLab ? "active" : undefined}
                    // isDisabled={filter.options.source === 'GNU' ? name === "GIA"  : filter.options.source === "LGN" ?  ["IGI", "GCAL"].includes(name) : undefined}
                    // isDisabled={ring.extraData.Label ? ["Jenny Packham","Reve"].includes(ring.extraData.Label) : false}
                    onClick={() => {
                      setBackToSelectDiamondPage(false);
                      setChangeMinMaxValue(false);
                      toggleScrollTop(false);
                      if (Array.isArray(filter.options?.lab) && findLab) {
                        const filterLab = filter.options?.lab.filter(
                          (f) => f !== name.toUpperCase()
                        );
                        if (filterLab.length === 0) {
                          return removeFilterKey("lab");
                        }
                        return handleFilterType(filterLab, "lab");
                      }
                      if (filter.options?.lab) {
                        handleFilterType(
                          [
                            ...(filter.options?.lab as string | string[]),
                            name.toUpperCase(),
                          ],
                          "lab"
                        );
                      } else {
                        handleFilterType([name.toUpperCase()], "lab");
                      }
                    }}
                  >
                    <span className="text-padding-top-7">{name}</span>
                  </Button>
                );
              })}
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"L:W Ratio"}>
              <InputRangeSlider
                min={+filter.minMaxValue.ratio.total_min_value as number}
                max={+filter.minMaxValue.ratio.total_max_value as number}
                selectedValue={
                  !filter.removedMinMax["ratio"]
                    ? [
                        +filter.minMaxValue.ratio.min_value as number,
                        +filter.minMaxValue.ratio.max_value as number,
                      ]
                    : undefined
                }
                step={0.01}
                isShowDollar={false}
                keyName={"ratio"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                options={filter.options}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                isReset={filter.isReset}
                rangeValue={(filter.options.ratio as string)?.split("-")}
                setRemovedMinMaxOptions={setRemovedMinMaxOptions}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Fluorescence"}>
              <LabelRangeSlider
                mark={fluorecence}
                keyName={"fluorecence"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.fluorecence as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Polish"}>
              <LabelRangeSlider
                mark={polish}
                keyName={"polish"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.polish as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Symmetry"}>
              <LabelRangeSlider
                mark={symmetry}
                keyName={"symmetry"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                filter={filter}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                rangeValue={(filter.options.symmetry as string)?.split("-")}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Table (%)"}>
              <InputRangeSlider
                min={+filter.minMaxValue.tbl.total_min_value as number}
                max={+filter.minMaxValue.tbl.total_max_value as number}
                selectedValue={
                  !filter.removedMinMax["table_per"]
                    ? [
                        +filter.minMaxValue.tbl.min_value as number,
                        +filter.minMaxValue.tbl.max_value as number,
                      ]
                    : undefined
                }
                isShowDollar={false}
                step={0.01}
                keyName={"table_per"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                options={filter.options}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                isReset={filter.isReset}
                rangeValue={(filter.options.table_per as string)?.split("-")}
                setRemovedMinMaxOptions={setRemovedMinMaxOptions}
              />
            </Label>
          </div>
          <div className={classes.items}>
            <Label text={"Depth (%)"}>
              <InputRangeSlider
                min={+filter.minMaxValue.depth.total_min_value as number}
                max={+filter.minMaxValue.depth.total_max_value as number}
                selectedValue={
                  !filter.removedMinMax["depth"]
                    ? [
                        +filter.minMaxValue.depth.min_value as number,
                        +filter.minMaxValue.depth.max_value as number,
                      ]
                    : undefined
                }
                isShowDollar={false}
                step={0.01}
                keyName={"depth"}
                handleFilterType={handleFilterType}
                removeFilterKey={removeFilterKey}
                options={filter.options}
                setBackToSelectDiamondPage={setBackToSelectDiamondPage}
                setChangeMinMaxValue={setChangeMinMaxValue}
                isReset={filter.isReset}
                rangeValue={(filter.options.depth as string)?.split("-")}
                setRemovedMinMaxOptions={setRemovedMinMaxOptions}
              />
            </Label>
          </div>
        </>
      ) : null}
    </>
  );
};
export default connector(DiamondFilterContent);
